<template lang="pug">
.page.d-flex.align-center
  nav-header
  login-backdrop
  v-row(align="center", justify="center")
    v-col(cols="12" sm="10" md="8" lg="6" xl="4")
      v-card(outlined).login-card
        v-form.px-4
          v-row(justify="center")
            v-col(cols="12").mb-5.pt-8
              h1.text-center.text-uppercase.font-weight-bold Login
          v-row(justify="center")
            v-col(cols="12" sm="10")
              v-text-field(type="email" label="E-mail" v-model="email" outlined hide-details prepend-inner-icon="mdi-email-outline" :error="errors" @input="resetErrors")
            v-col(cols="12" sm="10")
              v-text-field(type="password" label="Password" v-model="password" outlined hide-details prepend-inner-icon="mdi-key-outline" :error="errors" @input="resetErrors" @keyup.enter.prevent="login")
            v-col(cols="12" sm="10")
              v-row(justify="space-between")
                v-col.py-1.px-7(cols="auto")
                  nuxt-link(to="/user/forgot") Forgot your password?
                v-col.py-1.px-7(cols="auto")
                  v-switch.mt-0(dense hide-details label="Remember me" v-model="keep")
            v-col(cols="12" sm="10")
              v-btn(x-large color="primary" block @click="login") Login
            v-col(cols="12" sm="10")
              v-row.pb-8(justify="start")
                v-col.py-0.px-7(cols="auto")
                  nuxt-link(to="/register") You don't have an account?
      v-snackbar(v-model="snackbar") You need to confirm your email before you can use Meetr.
        v-btn.ml-2(text color="accent" @click="resendConfirmationEmail") Resend email
</template>

<script>
import Bugsnag from '@bugsnag/js'
import LoginBackdrop from '~/components/LoginBackdrop.vue'
import NavHeader from '~/components/NavHeader.vue'

export default {
  transition: 'fade-translate',
  components: { NavHeader, LoginBackdrop },
  head () {
    return this.$seo({
      title: 'Login'
    })
  },
  data () {
    return {
      keep: false,
      email: '',
      password: '',
      errors: false,
      snackbar: false,
      user_id: ''
    }
  },
  methods: {
    async login () {
      if (!this.errors) {
        const { email, password, keep } = this

        try {
          const { token } = await this.$axios.$post('/auth/login', { email, password })
          this.$store.commit('token', { token, keep })

          const user = await this.$axios.$get('/person').catch((e) => { return {} })
          this.$store.commit('user/set', user)

          this.$router.push('/room')
        } catch (error) {
          this.errors = true

          if (error.response) {
            const { status, data } = error.response

            if (status === 401) {
              this.user_id = data.id
              this.snackbar = true
            }
          } else {
            Bugsnag.notify(error)
          }
        }
      }
    },
    async resendConfirmationEmail () {
      try {
        this.resetErrors()
        await this.$axios.$post(`/auth/email/confirm/${this.user_id}`)
      } catch (e) {
        Bugsnag.notify(e)
      } finally {
        this.user_id = ''
      }
    },
    resetErrors () {
      this.snackbar = false
      this.errors = false
    }
  }
}
</script>
